import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { StaticImage } from 'gatsby-plugin-image';

import { useSiteMetaContext } from '../../context/SiteMetaContext';
import Container from '../../layout/Container';
import PlayButton from '../../components/PlayButton';

const Background = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colours.backgroundPaper};
`;

const Inner = styled(Container)`
  position: relative;
  display: flex;
  justify-content: center;
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    text-align: center;
  `}
`;

const VideoWrapper = styled.div`
  max-width: ${({ theme }) => theme.sizes.sizeByFactor(94)};
  margin: 0 auto;
  width: 100%;
`;
const VideoContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 56.25%;
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const Paragraph = styled.p`
  && {
    line-height: 1.7;
    margin: 0;
    max-width: 59rem;
    font-size: ${({ theme }) => theme.font.sizes.lg};
    font-weight: 400;
    ${({ theme }) => theme.breakpoint('tabletLandscape')`
      line-height: 1.4;
      font-size: ${({ theme }) => theme.font.sizes.xxxl};
    `}
    u {
      text-underline-position: under;
    }
    strong {
      font-weight: 700;
    }
  }
`;

const Shadow = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, transparent 100%);
  box-sizing: border-box;
  padding: ${({ theme }) => theme.sizes.lvl2};
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    padding: ${({ theme }) => theme.sizes.lvl3};
  `}
  :hover::before {
    opacity: 1;
  }
  ::before {
    content: '';
    transition: opacity 0.3s;
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

const LandingVideoCoverWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  div {
    height: 100%;
  }
  opacity: ${({ $hide }) => ($hide ? 0 : 1)};
  pointer-events: ${({ $hide }) => ($hide ? 'none' : 'auto')};
  transition: opacity 0.8s ease-in-out;
`;

const PositionedPlayButton = styled(PlayButton)`
  position: absolute;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
  span {
    font-size: 3.5rem;
  }
  ${({ theme }) => theme.breakpoint('phoneLarge')`
  top: 50%;
`}
`;

const VideoTitle = styled.strong`
  position: relative;
  color: ${({ theme }) => theme.colours.secondary};
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.font.sizes.lg};

  ${({ theme }) => theme.breakpoint('tabletLandscape')`
   font-size: ${({ theme }) => theme.font.sizes.xxl};
  `}
`;

const VideoSubTitle = styled(VideoTitle)`
  display: block;
  line-height: 1;
  color: ${({ theme }) => theme.colours.white};
`;

const LandingTrailerSection = () => {
  const playerRef = useRef(null);
  const { title, isFb, landingVideo } = useSiteMetaContext();
  const [playing, setPlaying] = useState(false);

  return (
    <Background>
      <Inner>
        <Paragraph>
          Struggle with <u>brain fog</u>, <u>sleep</u>, or <u>pain?</u>{' '}
          Suffering with <u>{isFb ? 'post-viral fatigue' : 'long-COVID'}</u>?
          Tens of millions of people <strong>have been failed</strong> by the
          medical system.
        </Paragraph>
      </Inner>
      <VideoWrapper>
        <VideoContainer>
          <ReactPlayer
            className="react-player"
            ref={playerRef}
            allowFullScreen
            playing={playing}
            url={landingVideo}
            controls
            width="100%"
            height="100%"
            config={{
              vimeo: {
                playerOptions: {
                  pip: true,
                },
              },
            }}
          />
          <LandingVideoCoverWrapper $hide={playing}>
            <StaticImage
              src="../../images/landing_trailer_cover.jpg"
              alt={`${title} landing video cover`}
              width={800}
              placeholder="blurred"
            />
            <Shadow>
              <VideoTitle className="strong">{title}</VideoTitle>
              <VideoSubTitle className="strong">
                EXPLAINED IN UNDER 3 MINUTES
              </VideoSubTitle>
            </Shadow>
            <PositionedPlayButton
              height="6rem"
              onClick={() => setPlaying(true)}
            />
          </LandingVideoCoverWrapper>
          {!playing && <></>}
        </VideoContainer>
      </VideoWrapper>
      <Inner>
        <Paragraph>
          Join the biggest online conference ever on Fatigue related conditions
          for real answers.
        </Paragraph>
      </Inner>
    </Background>
  );
};

export default LandingTrailerSection;
