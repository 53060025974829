import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import GiftSvgPath from '../images/gift.svg';
import Layout from '../layout/Layout';
import Seo from '../components/Seo';
import ScrollTarget from '../components/ScrollTarget';
import TooltipSvgInner from '../components/TooltipSvgInner';
import LandingIntroSection from '../sections/landing/landingIntro';
import RegistrationSection from '../sections/registration';
import YourHostSection from '../sections/yourHost';
import StickyRegistrationSection from '../sections/stickyRegistration';
import LandingGifts from '../sections/landing/landingGifts';
import LandingGiftsMoreDetailsSection from '../sections/landing/landingGiftsMoreDetails';
import WhatIsSuperConferenceSection from '../sections/whatIsSuperConference';
import ConferenceContentSection from '../sections/conferenceContent';
import HomepageExpertsSection from '../sections/homepageExperts';
import SponsorsSection from '../sections/sponsors';
import LandingTrailerSection from '../sections/landing/landingTrailer';
import LandingAdditionalContentSection from '../sections/landing/landingAdditionalContent';
import { useSiteMetaContext } from '../context/SiteMetaContext';

const RegistrationMobileOnly = styled(RegistrationSection)`
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
  display: none;
  `};
`;

const PaperBackground = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colours.backgroundPaper};
`;

const IndexPage = ({ location }) => {
  const { landingBg, landingBgMobile } = useStaticQuery(graphql`
    query {
      landingBg: file(relativePath: { eq: "landing_bg.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      landingBgMobile: file(relativePath: { eq: "landing_bg_mobile.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const { conferenceDateString, title } = useSiteMetaContext();
  // Affiliate Id is returned from infusionsoft's redirect. We store it here so we can post it when creating firebase registration later.
  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  if (params.affiliate) {
    localStorage.setItem('superconferenceAffiliate', params.affiliate);
  }

  const registrationTooltipInner = (
    <TooltipSvgInner
      svgPath={GiftSvgPath}
      content={
        <>
          Instant Access
          <br />
          Free Gifts
        </>
      }
    />
  );
  const registrationTitle = <span>It's free &amp; online</span>;
  const registrationSubtitle = (
    <div>
      Join the {title} <br />
      {conferenceDateString}
    </div>
  );

  return (
    <Layout>
      <Seo title="Home" />

      <LandingIntroSection
        title={
          <div>
            Feeling tired
            <br />
            all the time?
          </div>
        }
        subtitle={`Join the ${title} ${conferenceDateString}, it's FREE and
        ONLINE`}
        tooltipInner={registrationTooltipInner}
        landingBg={landingBg}
        landingBgMobile={landingBgMobile}
      />

      <RegistrationMobileOnly
        title={registrationTitle}
        subtitle={registrationSubtitle}
        tooltipInner={registrationTooltipInner}
      />

      <LandingTrailerSection />

      <ScrollTarget id="signup" />

      <RegistrationSection
        inline
        title={registrationTitle}
        subtitle={registrationSubtitle}
        tooltipInner={registrationTooltipInner}
      />

      <LandingGifts />

      <StickyRegistrationSection
        title="Register to Unlock Instantly"
        primaryButtonLabel="Click here to register"
      />

      <ConferenceContentSection />

      <WhatIsSuperConferenceSection />

      <ScrollTarget id="conferenceexperts" />
      <PaperBackground>
        <HomepageExpertsSection />
      </PaperBackground>

      <LandingAdditionalContentSection />

      <LandingGiftsMoreDetailsSection />

      <YourHostSection showHeader />

      <SponsorsSection />
    </Layout>
  );
};

export default IndexPage;
