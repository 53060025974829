import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';

import { ColourLevel } from '../../types';
import IncludedColouredTickPath from '../../images/included_coloured_tick.svg';

const Container = styled.div<{ $colourLevel: ColourLevel }>`
  background-color: white;
  color: ${({ theme, $colourLevel }) =>
    theme.colourLevels[$colourLevel].text.FILLED};
  background-color: ${({ theme, $colourLevel }) =>
    theme.colourLevels[$colourLevel].background.FILLED};
  border-radius: ${({ theme }) => theme.sizes.lvl2};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${({ theme }) => theme.sizes.lvl7};

  padding: 0 ${({ theme }) => theme.sizes.lvl3}
    ${({ theme }) => theme.sizes.lvl3};
`;

const TitlesContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.sizes.lvl2};
  height: ${({ theme }) => theme.sizes.lvl10};
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    height: ${({ theme }) => theme.sizes.lvl14};
  `}
`;

const Title = styled.h1`
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1;
  margin-bottom: ${({ theme }) => theme.sizes.lvl4};
  &:first-line {
    font-weight: 400;
  }
`;

const SubTitle = styled.span<{ $colourLevel: ColourLevel }>`
  background-color: ${({ theme, $colourLevel }) =>
    theme.colourLevels[$colourLevel].text.FILLED};
  color: ${({ theme, $colourLevel }) =>
    theme.colourLevels[$colourLevel].background.FILLED};
  border-radius: 1rem;
  padding: ${({ theme }) => `${theme.sizes.lvl1} ${theme.sizes.lvl2}`};
  font-weight: 500;
`;

const StyledAvatarContainer = styled.div`
  max-width: 80%;
  margin: -${({ theme }) => theme.sizes.lvl7} auto ${({ theme }) =>
      theme.sizes.lvl3} auto;
  border: 3px solid white;
`;

const Content = styled.div`
  ul {
    li {
      background: none;
      position: relative;
      font-size: ${({ theme }) => theme.font.sizes.sm};
      font-weight: 400;
      list-style: none;
      margin: 0;
      background: url(${IncludedColouredTickPath}) no-repeat left center;
      padding: ${({ theme }) =>
        `${theme.sizes.lvl1} ${theme.sizes.lvl1} ${theme.sizes.lvl1} ${theme.sizes.lvl6}`};
    }
  }
`;

interface GiftCardProps {
  className?: string;
  title?: string;
  subtitle?: string;
  image?: ImageDataLike;
  content?: string;
  colourLevel?: ColourLevel;
}

const GiftCard = ({
  className,
  title,
  subtitle,
  image,
  content,
  colourLevel = ColourLevel.SECONDARY,
}: GiftCardProps) => {
  return (
    <Container className={className} $colourLevel={colourLevel}>
      {image && (
        <StyledAvatarContainer>
          <GatsbyImage alt={`Gift ${title} image`} image={getImage(image)} />
        </StyledAvatarContainer>
      )}
      <TitlesContainer>
        <Title className="h3">{title}</Title>
        <SubTitle $colourLevel={colourLevel}>{subtitle}</SubTitle>
      </TitlesContainer>

      {content && (
        <Content
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      )}
    </Container>
  );
};

export default GiftCard;
